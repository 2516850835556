<template>
  <div class="service">
    <div class="service_content">
      <div class="content">
        <!-- 顶部面包屑 s -->
        <bread-crumb :nav_title="nav_title" />
        <!-- 顶部面包屑 end-->
        <!-- 海师详情信息 -->
        <div class="hsdetail_bg">
          <div class="amount">
            <!-- 左边详情 -->
            <!-- 头像 -->
            <div class="avatar_detail">
              <div>
                <el-avatar :src="userInfo.head_link"></el-avatar>
              </div>
              <div class="avatar_name">
                <span>{{ userInfo.name }}</span>
                <div class="record">
                  <span>{{ userInfo.jrtxnf }} ·{{ userInfo.sqjj }}</span>
                </div>
              </div>
            </div>
            <!-- 右边部分 -->
            <div class="hsdetail_right">
              <!-- 应付金额 -->
              <div class="cope_pay">
                <span>¥{{ sum }}</span>
                <p>消费金额</p>
              </div>
              <i></i>
              <!-- 批量导出 -->
              <div class="plexport">
                <el-tag @click="exportExcel">批量导出</el-tag>
              </div>
            </div>
          </div>
        </div>
        <!-- 海师详情表格 -->
        <div class="hsdetail_tables">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              prop="teacher_name"
              label="海师姓名"
            ></el-table-column>
            <el-table-column prop="course_name" label="课程名称">
            </el-table-column>
            <el-table-column
              prop="class_time"
              label="上课时间"
              show-overflow-tooltip
            >
              <span>2021-03-10</span>
              <p>12:32</p>
            </el-table-column>
            <el-table-column
              prop="service_category_name"
              label="服务类别"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="service_content"
              label="服务内容"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="course_duration"
              label="课程时长"
              show-overflow-tooltip
            >
              <span>75分钟</span>
              <p>超时</p>
            </el-table-column>
            <el-table-column
              prop="course_price"
              label="课程单价"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="confirmprice"
              label="确认单价"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="bb_user_name"
              label="学生姓名"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="teach"
              label="对接老师"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="coursestate"
              label="课程状态"
              show-overflow-tooltip
            >
              <template slot-scope="scope">{{
                scope.row.is_end_course ? "全部结束" : "未结束"
              }}</template>
            </el-table-column>
            <el-table-column
              prop="auditstate"
              label="审核状态"
              show-overflow-tooltip
            >
              <template slot-scope="scope">{{
                scope.row.is_financial_audit ? "已通过" : "已驳回"
              }}</template>
            </el-table-column>
            <el-table-column
              prop="paystate"
              label="付款状态"
              show-overflow-tooltip
            >
              <template slot-scope="scope">{{
                scope.row.is_payment ? "已结算" : "未结算"
              }}</template>
            </el-table-column>
          </el-table>
          <!-- 分页器 -->
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            :current-page.sync="currentPage"
            @current-change="pageChange"
          >
          </el-pagination>
          <!-- 导出数据专用表格 -->

          <el-table
            :data="selectList"
            tooltip-effect="dark"
            style="width: 100%"
            id="daochu2"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              prop="teacher_name"
              label="海师姓名"
            ></el-table-column>
            <el-table-column prop="course_name" label="课程名称">
            </el-table-column>
            <el-table-column
              prop="class_time"
              label="上课时间"
              show-overflow-tooltip
            >
              <span>2021-03-10</span>
              <p>12:32</p>
            </el-table-column>
            <el-table-column
              prop="service_category_name"
              label="服务类别"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="service_content"
              label="服务内容"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="course_duration"
              label="课程时长"
              show-overflow-tooltip
            >
              <span>75分钟</span>
              <p>超时</p>
            </el-table-column>
            <el-table-column
              prop="course_price"
              label="课程单价"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="confirmprice"
              label="确认单价"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="bb_user_name"
              label="学生姓名"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="teach"
              label="对接老师"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="coursestate"
              label="课程状态"
              show-overflow-tooltip
            >
              <template slot-scope="scope">{{
                scope.row.is_end_course ? "全部结束" : "未结束"
              }}</template>
            </el-table-column>
            <el-table-column
              prop="auditstate"
              label="审核状态"
              show-overflow-tooltip
            >
              <template slot-scope="scope">{{
                scope.row.is_financial_audit ? "已通过" : "已驳回"
              }}</template>
            </el-table-column>
            <el-table-column
              prop="paystate"
              label="付款状态"
              show-overflow-tooltip
            >
              <template slot-scope="scope">{{
                scope.row.is_payment ? "已结算" : "未结算"
              }}</template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadCrumb from "../components/breadcrumb";
import { getClientDetail } from "@/api/financial.js";
// 引入导出Excel表格依赖
import FileSaver from "file-saver";
import XLSX from "xlsx";
export default {
  data() {
    return {
      nav_title: [
        { name: "财务中心" },
        { name: "客户统计"},
        { name: "客户详情" },
      ],
      tableData: [],
      total: 0,
      sum: 0,
      userInfo: {},
      selectList: [],
         currentPage: 1,
      pageSize: 1,
      id:'',
    };
  },

  components: {
    breadCrumb: breadCrumb,
  },
  methods: {
    getClientDetail() {
      getClientDetail({ user_id: this.id }).then((res) => {
        let { total, list } = res.data.list;
        this.total = total;
        this.tableData = list;
        this.userInfo = res.data.user_infor;
        this.pageSize = list.limit;
        this.sum = res.data.sum
      });
    },
    //定义导出Excel表格事件
    exportExcel() {
      if (this.selectList.length === 0) {
        this.$message({
          message: "无数据",
          type: "warning",
        });
        return;
      }
      // 解决生成重复数据-因为使用l fixed属性
      var fix = document.querySelector("#daochu2 .el-table__fixed-right");
      var wb;
      // 判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
      if (fix) {
        /* 从表生成工作簿对象 */
        wb = XLSX.utils.table_to_book(
          document.querySelector("#daochu2").removeChild(fix),
          { raw: true }
        );
        document.querySelector("#daochu2").appendChild(fix);
      } else {
        wb = XLSX.utils.table_to_book(document.querySelector("#daochu2"), {
          raw: true,
        });
      }

      /* 获取二进制字符串作为输出 */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          // Blob 对象表示一个不可变、原始数据的类文件对象。
          // Blob 表示的不一定是JavaScript原生格式的数据。
          // File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
          // 返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
          new Blob([wbout], { type: "application/octet-stream" }),
          // 设置导出文件名称
          "统计核算.xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      this.$refs.multipleTable.clearSelection();
      return wbout;
    },
    //表格选中事件回调
    handleSelectionChange(val) {
      //选中批量导出的 给导出专用表格赋值数据
      this.selectList = val; //选中数据
    },
    // 页码切换
    pageChange(page) {
      this.currentPage = page;
      this.getClientDetail();
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getClientDetail();
  },
};
</script>

<style lang="scss" scoped>
// 隐藏导出表格
#daochu2 {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.service {
  background: #f5f5f5;
  .service_content {
    margin: 0 auto;
    width: 1200px;
    // 海师头像
    .hsdetail_bg {
      background: url("./image/hsbg.png");
      width: 100%;
      height: 145px;
      // 海师名字
      .avatar_name {
        margin-top: 43px;
        margin-left: 20px;
        color: #935d14;
        font-weight: 400;
        line-height: 20px;
        font-size: 13px;
        span {
          font-size: 16px;
          font-weight: 500;
        }
        .record > span {
          font-size: 13px;
        }
      }
      .amount {
        display: flex;
        .hsdetail_right {
          margin-left: 220px;
          margin-top: 45px;
          color: #666666;
          font-size: 24px;
          letter-spacing: 1px;
          font-weight: 500;
          display: flex;
          .cope_pay {
            width: 100%;
            text-align: center;
            margin-left: 100px;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            color: #999999;
            line-height: 24px;
            margin-top: 10px;
          }
          i {
            width: 1px;
            height: 54px;
            background: #ededed;
            margin-left: 180px;
          }
          .plexport {
            margin-left: 215px;
          }
          /deep/ .el-tag {
            width: 90px;
            height: 28px;
            border-radius: 4px;
            border: 1px solid rgba(24, 144, 255, 0.3);
            background-color: #ffffff;
            color: #1890ff;
            line-height: 26px;
            margin-top: 15px;
            padding-left: 18px;
            cursor: pointer;
          }
        }
      }
      /deep/.el-avatar {
        width: 80px;
        height: 80px;
        margin-top: 35px;
        margin-left: 30px;
      }
      /deep/.el-divider--vertical {
        background: #935d14;
      }
    }
    // 海师表格列表
    .hsdetail_tables {
      margin-top: 20px;
      background: #ffffff;
      padding: 20px;
      /deep/ .el-table__body,
      .el-table__footer,
      .el-table__header {
        table-layout: fixed;
        border-collapse: separate;
        font-size: 12px;
      }
      /deep/.el-table th,
      .el-table tr {
        background-color: #f5f5f5;
        font-weight: 500;
      }
      /deep/ .el-table th {
        text-align: center;
      }
      /deep/.el-table td,
      .el-table th {
        text-align: center;
      }
    }
    // 分页器start
    /deep/ .el-pagination {
      color: #999999;
      text-align: center;
      margin-top: 40px;
      .el-pager li:not(.disabled).active {
        width: 44px;
        height: 30px;
        background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
        border-radius: 4px 8px 4px 8px;
        font-size: 16px;
        color: #ffffff;
      }
      .btn-prev,
      .el-pager li {
        width: 44px;
        height: 30px;
        border-radius: 4px 8px 4px 8px;
        border: 1px solid #ededed;
        font-size: 16px;
        font-weight: 400;
        color: #999999;
      }
      .btn-next {
        width: 44px;
        height: 30px;
        border-radius: 4px 8px 4px 8px;
        border: 1px solid #ededed;
        color: #cfcfcf;
      }
    }
    .avatar_detail {
      display: flex;
    }
  }
}
</style>
